const BRL_BANKS = [
  'Access Soluções de Pagto SA (332)',
  'Advanced CC Ltda (117)',
  'Ágora CTVM SA (565)',
  'Asaas IP SA (461)',
  'Ativa SA Investimentos CCTVM (188)',
  'Azumi Distribuidora de Títulos (463)',
  'B&T Corretora de Câmbio Ltda. (080)',
  'Banco Bradescard (063)',
  'Banco BTG Pactual SA (208)',
  'Central Bank of Brazil (002)',
  'Banco Cifra (233)',
  'MI Development Bank (023)',
  'Banco Digio (335)',
  'Banco do Brasil SA (001)',
  'Banco Finaxis SA (094)',
  'Banco Genial (125)',
  'Banco Inbursa (012)',
  'Banco Inter (077)',
  'Banco Investcred Unibanco SA (249)',
  'Banco Itaú Consignado SA (029)',
  'Banco John Deere SA (217)',
  'Banco Master S/A (243)',
  'Original Bank (212)',
  'Banco Pan SA (623)',
  'Banco Semear (743)',
  'Banco Senff SA (276)',
  'Banco Sicoob SA (756)',
  'Banco Sistema (754)',
  'Banco Sumitomo Mitsui Brasileiro (464)',
  'Banco Topázio SA (082)',
  'Banco Voiter (653)',
  'Bancoseguro SA (081)',
  'Bari Companhia Hipotecária (268)',
  'Banco ABC Brasil SA (246)',
  'Banco ABN AMRO SA (075)',
  'Banco Agibank SA (121)',
  'Banco Alfa SA (025)',
  'Banco Andbank SA (065)',
  'Banco Arbi SA (213)',
  'Banco B3 SA (096)',
  'Banco Bandepe SA (024)',
  'Banco Banestes SA (021)',
  'Banco BBI SA (036)',
  'Banco BMG SA (318)',
  'Banco BNP Paribas Brasil SA (752)',
  'Banco Bocom BBM SA (107)',
  'Banco Bradesco Berj SA (122)',
  'Banco Bradesco Financ. SA (394)',
  'Banco Bradesco SA (237)',
  'Banco BS2 SA (218)',
  'Banco BV SA (413)',
  'Banco C6 Consig (626)',
  'Banco C6 SA (336)',
  'Banco Caixa Geral Brasil SA (473)',
  'Banco Cargill SA (040)',
  'Banco CCB Brasil SA (320)',
  'Banco Cédula SA (266)',
  'Banco Cetelem SA (739)',
  'Banco Citibank SA (745)',
  'Banco Clássico SA (241)',
  'Banco Cooperativo Sicredi SA (748)',
  'Banco Credit Agricole Brasil SA (222)',
  'Banco Credit Suisse SA (505)',
  'Banco Crefisa SA (069)',
  'Banco da Amazônia SA (003)',
  'Banco da China Brasil SA (083)',
  'Banco Daycoval SA (707)',
  'Banco Digimais SA (654)',
  'Banco do Estado de Sergipe SA (047)',
  'Banco do Estado do Pará SA (037)',
  'Banco do Estado do Rio Grande do Sul SA (041)',
  'Banco do Nordeste do Brasil SA (004)',
  'Banco Fator SA (265)',
  'Banco Fibra SA (224)',
  'Banco Guanabara SA (612)',
  'Banco HSBC SA (269)',
  'Banco Industrial do Brasil SA (604)',
  'Banco ItaúBank SA (479)',
  'Banco JP Morgan SA (376)',
  'Banco KDB Brasil SA (076)',
  'Banco KEB Hana do Brasil SA (757)',
  'Banco La Nacion Argentina (300)',
  'Banco La Provincia B Aires BCE (495)',
  'Banco Letsbank SA (630)',
  'Banco Luso Brasileiro SA (600)',
  'Banco Mercantil do Brasil SA (389)',
  'Banco Mizuho SA (370)',
  'Banco Modal SA (746)',
  'Banco Morgan Stanley SA (066)',
  'Banco MUFG Brasil SA (456)',
  'Banco Ourinvest SA (712)',
  'Banco Paulista SA (611)',
  'Banco Pine SA (643)',
  'Banco Rabobank Intl Brasil SA (747)',
  'Banco Rendimento SA (633)',
  'Banco Ribeirão Preto SA (741)',
  'Banco Rodobens SA (120)',
  'Banco Safra SA (422)',
  'Banco Santander Brasil SA (033)',
  'Banco Société Générale Brasil (366)',
  'Banco Sofisa SA (637)',
  'Banco Triângulo SA (634)',
  'Banco Tricury SA (018)',
  'Banco Votorantim SA (655)',
  'Banco VR SA (610)',
  'Banco Western Union (119)',
  'Banco Woori Bank do Brasil SA (124)',
  'Banco XP SA (348)',
  'Banco J. Safra SA (074)',
  'BCV Banco de Crédito e Varejo SA (250)',
  'BGC Liquidez DTVM Ltda (134)',
  'BMP SCMEPP Ltda (274)',
  'BNDES (007)',
  'BNY Mellon Banco SA (017)',
  'BofA Merrill Lynch BM SA (755)',
  'BR Partners BI (126)',
  'Braza Bank SA Banco de Câmbio (128)',
  'BRB Banco de Brasília SA (070)',
  'BRL Trust Distribuidora de Títulos e Valores Mobiliários (173)',
  'Broker Brasil Corretora de Câmbio (142)',
  'C. Suisse Hedging Griffo CV S/A (011)',
  'Caixa Econômica Federal (104)',
  'Carol DTVM Ltda. (288)',
  'Cartos SCD SA (324)',
  'Caruana SCFI (130)',
  'Casa Crédito SA SCM (159)',
  'CCM Desp Trans SC e RS (016)',
  'CCR de Abelardo Luz (322)',
  'CCR de São Miguel do Oeste (273)',
  'Celcoin Instituição de Pagamento (509)',
  'Citibank NA (477)',
  'CM Capital Markets CCTVM Ltda (180)',
  'Codepe CVC SA (127)',
  'Confederação Nacional das Cooperativas Centrais Unicred (136)',
  'Confidence CC SA (060)',
  'Central Credit Cooperative (085)',
  'Cora SCD SA (403)',
  'Credialiança CCR (098)',
  'Credicoamo Crédito Rural Cooperativa (010)',
  'Credisan CC (089)',
  'Credisis Central de Cooperativas (097)',
  'Credsystem Sociedade de Crédito (428)',
  'Cresol Confederação (133)',
  'Delcred Sociedade de Crédito (435)',
  'Deutsche Bank SA Banco Alemão (487)',
  'Dock Payment Institution (301)',
  'Ebanx IP Ltda. (383)',
  'Ebury Banco de Câmbio SA (144)',
  'EFI SA Instituição de Pagamento (364)',
  'Facta SA CFI (149)',
  'Fair CC SA (196)',
  'Fiducia SCMEPP Ltda (382)',
  'Finvest Distribuidora de Títulos e Valores (512)',
  'Fitbank Instituição de Pagamento (450)',
  'Frente Corretora de Câmbio SA (285)',
  'Gazincred SA Sociedade de Crédito Financiamento e Investimento (478)',
  'Genial Investimentos CVM SA (278)',
  'Get Money CC Ltda (138)',
  'Global SCM Ltda (384)',
  'Goldman Sachs do Brasil BM SA (064)',
  'Guide Investimentos SA Corretora de Valores (177)',
  'Guitta CC Ltda (146)',
  'Haitong Banco de Investimento do Brasil SA (078)',
  'Hemera DTVM Ltda (448)',
  'Hipercard BM SA (062)',
  'HR Digital Sociedade de Crédito (523)',
  'HS Financeira (189)',
  'Hub IP SA (396)',
  'IB CCTVM SA (271)',
  'ICAP do Brasil CTVM Ltda. (157)',
  'ICBC do Brasil BM SA (132)',
  'ID Corretora de Títulos e Valores Mobiliários (439)',
  'Intesa Sanpaolo Brasil SA BM (139)',
  'Issuer IP Ltda. (597)',
  'Itaú Unibanco SA (341)',
  'Iugu Payment Institution (401)',
  'JPMorgan Chase Bank (488)',
  'Kirton Bank (399)',
  'Lastro RDV DTVM Ltda (293)',
  'Lecca CFI SA (105)',
  'Levycam CCV Ltda (145)',
  'Mercado Pago IP Ltda. (323)',
  'Mirae Asset Brasil CCTVM Ltda (447)',
  'Neon Corretora de Títulos e Valores (113)',
  'Neon Pagamentos SA Instituição de Pagamento (536)',
  'Nova Futura CTVM Ltda (191)',
  'Novo Banco Continental SA (753)',
  'Nu Invest Corretora de Valores (140)',
  'Nu Payments IP (260)',
  'Oliveira Trust DTVM SA (111)',
  'Omni Banco SA (613)',
  'Órama DTVM SA (325)',
  'Oslo Capital DTVM SA (331)',
  'PagSeguro Internet IP SA (290)',
  'Paraná Banco SA (254)',
  'Parati CFI SA (326)',
  'Pefisa SACFI (174)',
  'PicPay (380)',
  'PicPay Bank Banco Múltiplo SA (079)',
  'Pinbank Brasil Instituição de Pagamento (529)',
  'Planner CV SA (100)',
  'Planner Sociedade de Crédito (410)',
  'Polocred SCMEPP Ltda (093)',
  'QI SCD SA (329)',
  'Qista SA Crédito Financiamento e Investimento (516)',
  'RB Investimentos DTVM Ltda (283)',
  'Reag DTVM SA (528)',
  'Renascença DTVM Ltda (101)',
  'RTS - Financial Transfers and Solutions (590)',
  'RJI Corretora de Títulos e Valores (506)',
  'Sefer Investimentos DTVM Ltda (407)',
  'Senso CCVM SA (545)',
  'Serviçoop (190)',
  'Simpaul (365)',
  'Singulare CTVM SA (363)',
  'Sisprime do Brasil Cooperativa de Crédito (084)',
  'Social Bank Banco Múltiplo SA (412)',
  'Socred SA SCMEPP (183)',
  'SRM Bank (533)',
  'State Street BR SA Banco Comercial (014)',
  'Stone Instituição de Pagamento (197)',
  'Superdigital IP SA (340)',
  'Scotiabank Brasil (751)',
  'Terra Investimentos DTVM (307)',
  'Toro CTVM SA (352)',
  'Travelex Banco de Câmbio SA (095)',
  'Treviso CC SA (143)',
  'Trinus Capital DTVM (360)',
  'Trinus Sociedade de Crédito (444)',
  'Trustee DTVM Ltda (438)',
  'Tullett Prebon Brasil CVC Ltda (131)',
  'UBS Brasil BI SA (129)',
  'UBS Brasil CCTVM SA (015)',
  'Unavanti Sociedade de Crédito (460)',
  'Unida DTVM Ltda (194)',
  'Uniprime Coopcentral Ltda (099)',
  'UY3 SCD S/A (457)',
  'Vips CC Ltda (298)',
  'Vortx DTVM Ltda (310)',
  'Warren CVMC Ltda (371)',
  'Will Financeira SACFI (280)',
  'XP Investimentos CCTVM S/A (102)',
];

const COL_BANKS = [
  'Ban100 S.A (1558)',
  'Banco Agrario (1040)',
  'Banco Av Villas (1052)',
  'Banco BTG Pactual (1805)',
  'Banco Caja Social BCSC SA (1032)',
  'Banco Contactar (1819)',
  'Banco Cooperativo Coopcentral (1066)',
  'Banco Davivienda S.A (1051)',
  'Banco Falabella S.A. (1062)',
  'Banco Finandina S.A. (1063)',
  'Banco GNB Sudameris (1012)',
  'Banco J.P. Morgan Colombia S.A. (1071)',
  'Banco Mundo Mujer (1047)',
  'Banco Pichincha (1060)',
  'Banco Popular (1002)',
  'Banco Santander de Negocios Colombia S.A (1065)',
  'Banco Serfinanza S.A (1069)',
  'Banco Union S.A (1303)',
  'Banco W (1053)',
  'Banco de Bogota (1001)',
  'Banco de Occidente (1023)',
  'Banco de las Microfinanzas - Bancamia S.A. (1059)',
  'Bancoldex S.A. (1031)',
  'Bancolombia (1007)',
  'Bancoomeva (1061)',
  'BBVA Colombia (1013)',
  'BNP Paribas Colombia (1042)',
  'Bold CF (1808)',
  'Citibank (1009)',
  'Coink (1812)',
  'Coltefinanciera S.A (1370)',
  'Confiar Cooperativa Financiera (1292)',
  'Coofinep Cooperativa Financiera (1291)',
  'Cooperativa Financiera de Antioquia (1283)',
  'Cootrafa Cooperativa Financiera (1289)',
  'Daviplata (1551)',
  'Ding Tecnipagos S.A (1802)',
  'Financiera Juriscoop S.A. Compañia de Financiamiento (1121)',
  'Global66 (1814)',
  'ITAU (1006)',
  'Iris (1637)',
  'JFK Cooperativa Financiera (1286)',
  'Lulo Bank S.A. (1070)',
  'Mibanco S.A. (1067)',
  'Movii (1801)',
  'NU Colombia (1809)',
  'Nequi (1507)',
  'Powwi (1803)',
  'Rappipay (1811)',
  'Santander Consumer (1813)',
  'Scotiabank Colpatria S.A (1019)',
  'Uala (1804)',
];

const generateBankOptions = bankList =>
  bankList.map(bank => ({
    label: bank,
    value: bank,
  }));

export const BRL_BANKS_OPTIONS = generateBankOptions(BRL_BANKS);
export const COL_BANKS_OPTIONS = generateBankOptions(COL_BANKS);
